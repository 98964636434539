document.addEventListener('DOMContentLoaded', (event) => {
    function wowEffect(newParams) {
        const params = {
            element: null,
            effectName: 'fadeInUp',
            duration: 1,
            delay: 0,
            increaseIndex: false,
            increaseIndexMob: true,
            windowWidth: window.innerWidth,
            speed: 10
        };
        const obj = Object.assign(params, newParams)
        
        if(!obj.increaseIndexMob && obj.windowWidth < 700) {
            obj.increaseIndex = false
        }

        let el = obj.element;
        if (el) {
            el.forEach((el, i) => {
                if (!el.classList.contains('wow')) {
                    el.classList.add('wow', obj.effectName);
                    el.setAttribute('data-wow-duration', obj.duration + 's');
                    el.setAttribute('data-wow-delay', obj.delay + (obj.increaseIndex ? (i / obj.speed) : 0) + 's');
                }

            })
        }
    }


    const heroAnim = () => {
        const dskHeroContentTitle = document.querySelectorAll('.fullpage-wrapper .index-hero__title span');
        const mobHeroContentTitle = document.querySelectorAll('.index-mob .index-hero__title span');
        const dskHeroContentDsc = document.querySelectorAll('.fullpage-wrapper .index-hero__dsc');
        const mobHeroContentDsc = document.querySelectorAll('.index-mob .index-hero__dsc');
        const dskHeroContentBtn = document.querySelectorAll('.fullpage-wrapper .index-hero__btn');
        const mobHeroContentBtn = document.querySelectorAll('.index-mob .index-hero__btn');
        const dskHeroContentRequired = document.querySelectorAll('.fullpage-wrapper .index-hero__required');
        const mobHeroContentRequired = document.querySelectorAll('.index-mob .index-hero__required');

        wowEffect({
            element: dskHeroContentTitle,
            duration: .5,
            delay: .15,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentTitle,
            duration: .5,
            delay: .15,
            increaseIndex: true
        });
        wowEffect({
            element: dskHeroContentDsc,
            duration: .5,
            delay: .8,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentDsc,
            duration: .5,
            delay: .8,
            increaseIndex: true
        });
        wowEffect({
            element: dskHeroContentBtn,
            duration: .5,
            delay: 1.15,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentBtn,
            duration: .5,
            delay: 1.15,
            increaseIndex: true
        });
        wowEffect({
            element: dskHeroContentRequired,
            duration: .5,
            delay: 1.3,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentRequired,
            duration: .5,
            delay: 1.3,
            increaseIndex: true
        });
    }

    const serviceBlock = document.querySelectorAll('.service__index, .service__link');


    wowEffect({
        element: serviceBlock,
        duration: .5,
        delay: .15
    });

    const serviceList = document.querySelectorAll('.service__block-list')

    serviceList.forEach(list => {

        wowEffect({
            element: list.querySelectorAll('li.block-list__point'),
            duration: .8,
            delay: .2,
            increaseIndex: true
        });
    })

    const serviceTitles = document.querySelectorAll('.service__title')

    serviceTitles.forEach(list => {

        wowEffect({
            element: list.querySelectorAll('span'),
            duration: .8,
            delay: .15,
            increaseIndex: true
        });
    })


    const indexCtaAnim = () => {
        const dctHeroContentTitle = document.querySelectorAll('.fullpage-wrapper .index-CTA__content .CTA__title span');
        const mobHeroContentTitle = document.querySelectorAll('.index-mob .index-CTA__content .CTA__title span');
        const dctHeroContentDsc = document.querySelectorAll('.fullpage-wrapper .index-CTA__content .CTA__description');
        const mobHeroContentDsc = document.querySelectorAll('.index-mob .index-CTA__content .CTA__description');
        const dctHeroContentBtn = document.querySelectorAll('.fullpage-wrapper .index-CTA__content  .CTA__btn');
        const mobHeroContentBtn = document.querySelectorAll('.index-mob .index-CTA__content  .CTA__btn');
        const dctHeroContentRequired = document.querySelectorAll('.fullpage-wrapper .index-CTA__content  .CTA__option');
        const mobHeroContentRequired = document.querySelectorAll('.index-mob .index-CTA__content  .CTA__option');

        wowEffect({
            element: dctHeroContentTitle,
            duration: .5,
            delay: 0,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentTitle,
            duration: .5,
            delay: 0,
            increaseIndex: true
        });
        wowEffect({
            element: dctHeroContentDsc,
            duration: .5,
            delay: 1,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentDsc,
            duration: .5,
            delay: 1,
            increaseIndex: true
        });
        wowEffect({
            element: dctHeroContentBtn,
            duration: .5,
            delay: 1.2,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentBtn,
            duration: .5,
            delay: 1.2,
            increaseIndex: true
        });
        wowEffect({
            element: dctHeroContentRequired,
            duration: .5,
            delay: 1.4,
            increaseIndex: true
        });
        wowEffect({
            element: mobHeroContentRequired,
            duration: .5,
            delay: 1.4,
            increaseIndex: true
        });
    }


    const CTAAnim = () => {
        const CTA = document.querySelectorAll('.CTA *');

        wowEffect({
            element: CTA,
            duration: .5,
            delay: 0,
            increaseIndex: true,
            speed: 20
        });
    }

    const footerAnim = () => {
        const footer = document.querySelectorAll('.footer-section');

        wowEffect({
            element: footer,
            duration: .5,
            delay: 0,
            increaseIndex: true
        });
    }

    const h2Block = () => {
        const title = document.querySelectorAll('.h2-block *');

        wowEffect({
            element: title,
            duration: .5,
            delay: 0,
            increaseIndex: true
        });

    };

    const globalAnimate = () => {

        const elems = [];


        const blogList = document.querySelectorAll('.blog-list > li');
        const singUp = document.querySelectorAll('.singUp__sUcontent.sUContent *');
        const sUOptions = document.querySelectorAll('.sUOptions__sUCards.sUCards li, .sUOptions__title, .sUOptions__dsc');
        const caseList = document.querySelectorAll('.case-list > li');
        const sectionHeaderH3 = document.querySelectorAll('.section-header-h3 *');
        const sUOptionsTabletContent = document.querySelectorAll('.sUOptions__tabletContent *');
        const priceTable = document.querySelectorAll('.price-table .table-head .tariff-cell')
        const contactForm = document.querySelectorAll('.contact-form__title, .contact-form form .wpcf7-form-control-wrap input, .contact-form form .wpcf7-form-control-wrap textarea, .contact-form form .wpcf7-form-control.wpcf7-submit')

        const blockList = document.querySelectorAll('.block-list');
        const icoList = document.querySelectorAll('.ico-list');


        const getPoint = (elem) => {
            elem.forEach(el => {
                const list = el.querySelectorAll('li');
                elems.push(list)
            })
        }

        getPoint(blockList);
        getPoint(icoList);


        elems.push(blogList, icoList, caseList, blockList, sectionHeaderH3, priceTable, singUp, sUOptions, sUOptionsTabletContent);

        elems.forEach(el => {
            wowEffect({
                element: el,
                duration: .5,
                delay: 0,
                increaseIndex: true
            });
        })
        // wowEffect({
        //     element: contactForm,
        //     duration: .5,
        //     delay: 0,
        //     increaseIndex: true,
        //     increaseIndexMob: false
        // });
    };


    const apiPageAnimate = () => {

        const contentBlock = document.querySelectorAll('.content-block > *');

        wowEffect({
            element: contentBlock,
            duration: .5,
            delay: 0,
            increaseIndex: true
        });
    };


    apiPageAnimate();
    h2Block();
    globalAnimate();
    footerAnim();
    if (!hasElement('#fullpage')) {
        CTAAnim();
    }
    indexCtaAnim();
    heroAnim();


    new WOW().init();


})












