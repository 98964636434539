if (hasElement('.singUp')) {
    const singUp = document.querySelector('.singUp')

    const business = singUp.querySelectorAll('.sUCards__point')
    const sUOptionsTabletContent = singUp.querySelector('.sUOptions__tabletContent')
    const sUOptionsTabletContentHeight = sUOptionsTabletContent.offsetHeight
    const sUListWrap = singUp.querySelector('.sUOptions__sUList-wrap')
    const sUListWrapHeight = sUListWrap.offsetHeight
    
    sUListWrap.style.height = 0;
    sUOptionsTabletContent.style.height = sUOptionsTabletContentHeight + 'px'






    const setActiveBusiness = () => {
        business.forEach(el => {
            el.addEventListener('click', ()=> {
                business.forEach(el => {
                    el.classList.remove('active')
                })
                el.classList.add('active')
                if (!singUp.classList.contains('options-active')) {
                    singUp.classList.add('options-active')
                    sUListWrap.style.height = sUListWrapHeight + 'px'
                    sUOptionsTabletContent.style.height = 0
                }
            })
        })
    };

    const changeOptionPage = () => {
        const continueBtn = document.querySelector('.singUp__continue')
        const backBtn = document.querySelector('.singUp__back')

        continueBtn.addEventListener('click', ()=> {
            singUp.classList.add('second-option-active')
        })
        backBtn.addEventListener('click', ()=> {
            singUp.classList.remove('second-option-active')
        })
    }






    changeOptionPage();
    setActiveBusiness();
}