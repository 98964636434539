if (hasElement('.index-page') && screenWidth > 1040) {
    fpStart()
} else {
    window.addEventListener('resize', e => {
        console.log('event is added')
        screenWidth = window.innerWidth
        if (screenWidth > 1040) {
            fpStart()
            console.log('fb is started')
        }
    })
}

window.addEventListener('resize', e => {
    if (hasElement('.fullpage-wrapper')) {
        screenWidth = window.innerWidth
        if (screenWidth < 1040) {
            fullpage_api.destroy();
            console.log('destroy')
        } else {
            fullpage_api.reBuild();
            // fullpage_api.moveTo(1);
            console.log('reBuild')
        }
    }
})



function fpStart() {
    body.classList.add('fbActive')
    const progressBar = document.querySelector('.progressBar')
    const dscVideo = document.querySelector('#vid');
    const vids = document.querySelectorAll('.index-vid-list .index-vid-point')

    let images = []

    for (let i = 0; i <= 180; i++) {
        let zero = '';
        if (i.toString().length === 1){
            zero = '00'
        } else if(i.toString().length === 2) {
            zero = '0'
        }
        images.push("".concat(base_url, "/gulp/build/assets/img/sequence/").concat(zero + i, ".jpg"));
    }

    const timeCodeReverse = (obj, vidLength) => {

        const timeCodes = obj;
        const objLength = Object.keys(obj).length

        for (let i = 1; i <= objLength; i++) {

            let timeCod;
            let timeCodReverse;

            if (i < objLength) {
                timeCod = timeCodes[i];
                timeCodReverse = vidLength - timeCod;

            } else {
                timeCod = vidLength / 2;
                timeCodReverse = vidLength / 2;
            }

            timeCodes[i] = [timeCod, timeCodReverse]

        }

        return timeCodes;
    }


    const setProgressBarHEight = (sectionAmt, progressBar, activeSection = 1) => {
        const oneSectionHeight = (100 / sectionAmt).toFixed(1) * activeSection + '%'

        const strip = progressBar.querySelector('div')
        strip.style.height = oneSectionHeight
    }

    const overlayGreenBackground = () => {
        const coating = document.querySelector('.index-vid-list .index-vid-green-coating')
        coating.classList.add('coating-pos-1')
        setTimeout(() => {
            coating.classList.add('coating-pos-2');
        }, 500);
        setTimeout(() => {
            coating.classList.remove('coating-pos-1', 'coating-pos-2');
        }, 700);
    }

    const changeVid = (i, dir, section) => {
        setTimeout(() => {
            vids.forEach(vid => {
                vid.style.display = 'none';

            });

            vids[i].style.display = 'block';

        }, 500);
    }

    const changeMenuColor = (i) => {
        const header = document.querySelector('.header-section')
        if (i === 0 || i === fullpage_api.test.left.length - 1) {
            header.classList.remove('dark-menu')
        } else {
            header.classList.add('dark-menu')
        }
    }

    let controlCurrent;


    document.addEventListener('keydown', function (event) {
        if (event.code == 'KeyR') {
            clearInterval(controlCurrent);
            dscVideo.pause()
        }
    });
    let num = 0;
    let timeout_holder;
    let limit_vid, direction, last = 'down';

    const playSequence = () => {
        if (num + 1 > images.length) {
            num = images.length;
        } else if (num - 1 < 0) {
            num = 0;
        }
        timeout_holder = setTimeout(function () {
            if (direction === 'down') {
                num++;
            } else {
                num--;
            }
            //console.log(num, limit_vid, direction);
            dscVideo.src = images[num];
            if (direction === 'down' && num < limit_vid) {
                playSequence();
            } else if (direction === 'up' && num > limit_vid) {
                playSequence();
            } else {
                clearTimeout(timeout_holder);
            }

        }, 38)
    }


    const playDscVid = (sectionIndex, dir) => {
        direction = dir;

        if (sectionIndex === 0) {
            limit_vid = 0;
        }
        if (sectionIndex === 1) {
            limit_vid = 18
        }
        if (sectionIndex === 2) {
            limit_vid = 57
        }
        if (sectionIndex === 3) {
            limit_vid = 120;
        }
        if (sectionIndex === 4) {
            limit_vid = 180;
        }
        if (sectionIndex === 5) {
            limit_vid = 180;
        }
        if (num > limit_vid && last !== dir) {
            direction = 'up'
        } else {
            clearTimeout(timeout_holder);
            playSequence();
        }
        if (num < limit_vid && last !== dir) {
            direction = 'down'
        } else {
            clearTimeout(timeout_holder);
            playSequence();
        }
        if (sectionIndex >= 5) {
            vids[2].play();
        }
        last = dir;

    };


    let fp = new fullpage('#fullpage', {
        autoScrolling: false,
        scrollBar: true,
        canStartPlay: true,
        scrollOverflow: true,
        actionsNumb: 0,
        onLeave: (origin, destination, direction) => {
            const width = window.screen.width;

            if (width > 1040) {

                setProgressBarHEight(fullpage_api.test.left.length, progressBar, destination.index + 1)

                const sectionLenght = fullpage_api.test.left.length - 1

                if (direction === 'down') {
                    if (destination.index === 1 || destination.index === sectionLenght) {
                        overlayGreenBackground();
                    }

                    if (destination.index === 1) {
                        changeVid(1)
                    }

                    if (destination.index === sectionLenght) {
                        changeVid(2)
                    }

                }

                if (direction === 'up') {
                    if (destination.index === 0 || destination.index === sectionLenght - 1) {
                        overlayGreenBackground();
                    }


                    if (destination.index === 0) {
                        changeVid(0)
                    } else if (destination.index === sectionLenght - 1) {
                        changeVid(1, direction, destination.index)
                    }
                }

                playDscVid(destination.index, direction)
                changeMenuColor(destination.index)
            }

        },
        afterLoad: () => {

        },
        afterRender: () => {
            const width = window.screen.width;

            if (width < 1040) {
                fullpage_api.setAutoScrolling(false);
                fullpage_api.setResponsive(true);

                if (history.scrollRestoration) {
                    history.scrollRestoration = 'auto';
                }
            } else {
                fullpage_api.setAutoScrolling(true);
                fullpage_api.setResponsive(false);

            }
            vids[0].play();
        },
        afterResize: (width) => {
            // fullpage_api.moveTo(1);
            // if (width < 1040) {
            //     fullpage_api.setAutoScrolling(false);
            //     fullpage_api.setResponsive(true);
            //
            //     if (history.scrollRestoration) {
            //         history.scrollRestoration = 'auto';
            //     }
            // } else {
            //     fullpage_api.setAutoScrolling(true);
            //     fullpage_api.setResponsive(false);
            // }
        }
    });


    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }

    if (document.querySelector('#fullpage')) {
        setProgressBarHEight(fullpage_api.test.left.length, progressBar)
    }
}