const addClassName = (check, desired, add) => {
    if (hasElement(check)) {
        const nav = document.querySelector(desired);

        nav.classList.add(add)
    }
}

addClassName('.content-navigator', '.case-navigator.case-detail-content__case-navigator', 'nav-margin')
addClassName('.price-table-section', '.h2-block.hero__h2-block.light', 'hero__h2-block-center')
