const body = document.querySelector('body');


/**
 *
 *     MOBILE MENU
 *
 * */
const burger = document.querySelector('.js-burger');
const menuItems = document.querySelectorAll('.mob-menu__list .mob-menu__point');
let screenWidth = window.innerWidth;


burger.addEventListener('click', e => {
    if (!body.classList.contains('menuMobActive')) {
        body.classList.add('menuMobActive')
        if (hasElement('.fullpage-wrapper')) {
            fullpage_api.setAutoScrolling(false);
        }
    } else {
        body.classList.remove('menuMobActive')
        if (hasElement('.fullpage-wrapper')) {
            fullpage_api.setAutoScrolling(true);
        }
    }
});

menuItems.forEach(el => {
    el.addEventListener('click', e => {
        body.classList.remove('menuMobActive')
        if (hasElement('.fullpage-wrapper')) {
            fullpage_api.setAutoScrolling(true);
        }
    });
});

const removeAllActiveClasses = (event) => {
    window.addEventListener(event, event => {
        body.classList.remove('menuMobActive');

    });
};

removeAllActiveClasses('resize');

