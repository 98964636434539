const currentList = document.querySelector('.currency-list');
if (hasElement('.currency-list')) {
    const currentPoints = currentList.querySelectorAll('.currency-point')


    currentPoints.forEach(el => {
        el.addEventListener('click', e => {
            currentPoints.forEach(elem => {
                elem.classList.remove('active')
            });
            el.classList.add('active');

            const tariffs = document.querySelectorAll('.th.tariff-cell .tariff__price')
            const mobTariffs = document.querySelectorAll('.mobile-price-table .th.tariff-cell .tariff__price')

            for (let i = 1; i < 4; i++) {
                const data = el.getAttribute('data-card-' + i);
                tariffs[i - 1].textContent = data;
                mobTariffs[i - 1].textContent = data;
            }
        })
    })
}
