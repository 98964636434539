const cookie = document.querySelector('.cookie-modal');

if (cookie) {
    const btn =  cookie.querySelector('.cookie-modal__btn')

    btn.addEventListener('click', e => {
        cookie.style.display = 'none';
    })




}
