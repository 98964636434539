/**
 *
 * Smooth scrolling on anchors
 *
 **/


function throttle(func, wait, options) {
    let context, args, result;
    let timeout = null;
    let previous = 0;
    if (!options) options = {};
    let later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        let now = Date.now();
        if (!previous && options.leading === false) previous = now;
        let remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}

let scroll = new SmoothScroll('a[href*="#"]', {
    header: '[data-scroll-header]',
    speed: 500,
    speedAsDuration: true,
});



// Добавялем throttle
window.addEventListener('scroll', throttle(scrollFunctionFirst, 200));

function scrollFunctionFirst() {
    scrollFunctionInner(body, 'js-fix-header', 0);
}

function scrollFunctionInner(classFound, classAdd, px) {


    if (classFound) {
        if (document.body.scrollTop > px || document.documentElement.scrollTop > px) {
            classFound.classList.add(classAdd);
        } else {
            classFound.classList.remove(classAdd);
        }
    }
}
scrollFunctionFirst();