window.addEventListener('DOMContentLoaded', ()=> {
  document.querySelector('.custom-select-wrapper').addEventListener('click', function() {
    this.querySelector('.custom-select').classList.toggle('open');
  });

  document.querySelectorAll(".custom-option").forEach(option => {
    option.addEventListener('click', function() {
      if (!this.classList.contains('selected')) {
        this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
        this.classList.add('selected');
        this.closest('.custom-select').querySelector('.custom-select__trigger span').textContent = this.textContent;
      }
    })
  })
});