const postAnchorList = document.querySelector('.content-navigator-list');

if (hasElement('.content-navigator-list')) {
    const postAnchorPoints = postAnchorList.querySelectorAll('.content-navigator__point')

    const postAnchors = document.querySelectorAll('.post-anchor');
    const windowCenter = window.screen.height / 2;

    const changeActiveAnchor = () => {

        const addActiveClass = (el) => {
            postAnchorPoints.forEach(el => {
                el.classList.remove('active')
            })
            el.classList.add('active')
        }

        postAnchors.forEach((anchor, i) => {
            if (anchor.getBoundingClientRect().y < windowCenter &&
                !postAnchorPoints[i].classList.contains('active')) {

                addActiveClass(postAnchorPoints[i])
            }

        })
        if (postAnchors[0].getBoundingClientRect().y > windowCenter) {
            addActiveClass(postAnchorPoints[0])
        }

    }

    window.addEventListener('scroll', throttle(changeActiveAnchor, 500));
}